import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import classes from "./Layout.module.css";
import CalendarPage from "../../pages/Calendar/Calendar";
import ResultsPage from "../../pages/Results/Results";
import Navigation from "../../components/Navigation/Navigation";
import MenuPopup from "../../components/MenuDialog/MenuDialog";

const Layout = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <Router>
      <div>
        <div className={classes.Calendar}>
          <div className={classes.ButtonWrapper}>
            <a className={classes.Button} onClick={togglePopup}>
              ВОЙТИ
            </a>
            <a
              className={classes.Button}
              href="https://enrollment.moisport.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Реестр организаций
            </a>
            <a
              className={classes.Button}
              href="https://enrollment.moisport.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Реестр объектов спорта
            </a>
          </div>
          <h1 className={classes.Title}>
            СИСТЕМА УЧЁТА СПОРТИВНЫХ ДОСТИЖЕНИЙ МОСКОВСКОЙ ОБЛАСТИ
          </h1>
          <MenuPopup visible={showPopup} onHide={togglePopup} />
        </div>
        <Routes>
          <Route path="/" element={<CalendarPage />} />
          <Route path="/calendar" element={<CalendarPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default Layout;
