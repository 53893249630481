import React, { useState, useEffect } from "react";
import classes from "./Calendar.module.css";
import EventFilters from "../../components/Filters/Filters";
import banner from "../../assets/banner.svg";
import Card from "../../components/Card/Card";
import { Paginator } from "primereact/paginator";
import localeData from "../../assets/locale/locale.json";
import { locale, addLocale } from "primereact/api";

addLocale("ru", localeData.ru);
locale("ru");

function CalendarPage() {
  const [eventsData, setEventsData] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [resultsAvailableCount, setResultsAvailableCount] = useState(0);
  const [applicationAvailableCount, setApplicationAvailableCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(12);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://7fe54de6-ee3a-477e-8e1c-10137a45514e.selstorage.ru/mos_oblast.json"
      );
      const data = await response.json();
      setEventsData(data);
      setFilteredEvents(data);
      updateCounts(data);
    };
    fetchData();
  }, []);

  const updateCounts = (data) => {
    const countResults = data.filter(
      (event) => event.event_results?.toLowerCase() === "да"
    ).length;
    const countApplications = data.filter(
      (event) => event.event_application?.toLowerCase() === "да"
    ).length;

    setResultsAvailableCount(countResults);
    setApplicationAvailableCount(countApplications);
  };

  const onPaginationChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const filterEvents = (filters) => {
    const filtered = eventsData.filter((event) => {
      return (
        (!filters.sport_type || event.sport_type === filters.sport_type) &&
        (!filters.event_type || event.event_type === filters.event_type) &&
        (!filters.event_status ||
          event.event_status === filters.event_status) &&
        (!filters.age || event.age === filters.age) && // Пример фильтрации по возрасту
        (!filters.event_region ||
          event.event_region === filters.event_region) &&
        (!filters.start_date || isDateInRange(event, filters.start_date)) &&
        (!filters.event_application ||
          event.event_application?.toLowerCase() === "да") &&
        (!filters.event_results || event.event_results?.toLowerCase() === "да")
      );
    });

    setFilteredEvents(filtered);
    updateCounts(filtered); // Обновляем счетчики после фильтрации
  };

  const isDateInRange = (event, filterDate) => {
    const startDate = new Date(event.start_date);
    const endDate = new Date(event.end_date);
    const filter = new Date(filterDate);
    return filter >= startDate && filter <= endDate;
  };

  return (
    <div className={classes.Calendar}>
      <a href="https://moisport.ru/" target="_blank">
        <svg
          width="201"
          height="61"
          viewBox="0 0 101 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M50.7932 26.8595C50.2356 27.9227 49.1205 28.6501 47.8382 28.6501C45.9982 28.6501 44.4928 27.1393 44.4928 25.2927C44.4928 23.4461 45.9982 21.9353 47.8382 21.9353C49.1205 21.9353 50.2356 22.6628 50.7932 23.7259H53.0234C52.3543 21.4877 50.2914 19.809 47.8382 19.809C44.8274 19.809 42.4299 22.2711 42.4299 25.2368C42.4299 28.2584 44.8831 30.6646 47.8382 30.6646C50.2914 30.6646 52.3543 29.0418 53.0234 26.7476H50.7932V26.8595Z"
            fill="black"
          ></path>
          <path
            d="M51.0162 3.63696L48.0054 7.83372L44.9388 3.63696H42.5971V14.2128H44.6601V6.49076L46.8345 9.45647H49.1763L51.295 6.49076V14.2128H53.3579V3.63696H51.0162Z"
            fill="black"
          ></path>
          <path
            d="M71.8689 19.809C68.8581 19.809 66.4606 22.2711 66.4606 25.2368C66.4606 28.2025 68.9138 30.6646 71.8689 30.6646C74.8796 30.6646 77.2771 28.2025 77.2771 25.2368C77.2771 22.2711 74.8796 19.809 71.8689 19.809ZM71.8689 28.6501C70.0289 28.6501 68.5235 27.1393 68.5235 25.2927C68.5235 23.4461 70.0289 21.9353 71.8689 21.9353C73.7088 21.9353 75.2142 23.4461 75.2142 25.2927C75.2699 27.1393 73.7645 28.6501 71.8689 28.6501Z"
            fill="black"
          ></path>
          <path
            d="M60.7736 3.46936C57.7628 3.46936 55.3653 5.93146 55.3653 8.89716C55.3653 11.9188 57.8185 14.325 60.7736 14.325C63.7844 14.325 66.1819 11.8629 66.1819 8.89716C66.1819 5.8755 63.7844 3.46936 60.7736 3.46936ZM60.7736 12.2546C58.9336 12.2546 57.4282 10.7437 57.4282 8.89716C57.4282 7.05059 58.9336 5.53976 60.7736 5.53976C62.6135 5.53976 64.1189 7.05059 64.1189 8.89716C64.1189 10.7437 62.6135 12.2546 60.7736 12.2546Z"
            fill="black"
          ></path>
          <path
            d="M85.0829 19.9766H79.3401V30.5524H81.403V27.4748H85.0829C87.1459 27.4748 88.8185 25.7961 88.8185 23.7257C88.7628 21.6553 87.1459 19.9766 85.0829 19.9766ZM85.0829 25.3484H81.403V22.047H85.0829C85.975 22.047 86.6998 22.7744 86.6998 23.6697C86.6998 24.621 85.975 25.3484 85.0829 25.3484Z"
            fill="black"
          ></path>
          <path
            d="M55.0307 19.9766V30.5524H57.0937V22.047H62.3904V30.5524H64.5091V19.9766H55.0307Z"
            fill="black"
          ></path>
          <path
            d="M100.75 19.9766H90.2125V22.047H94.4499V30.5524H96.5128V22.047H100.75V19.9766Z"
            fill="black"
          ></path>
          <path
            d="M75.8276 3.63696L70.252 10.8554V3.63696H68.1891V14.2128H70.252L75.8276 6.93841V14.2128H77.8905V3.63696H75.8276Z"
            fill="black"
          ></path>
          <path
            d="M73.0397 2.96571C74.8239 2.96571 76.2177 1.79062 76.3293 0H74.6566C74.5451 0.783395 73.876 1.28701 72.9839 1.28701C72.0918 1.28701 71.4228 0.783395 71.3113 0H69.6386C69.8616 1.79062 71.2555 2.96571 73.0397 2.96571Z"
            fill="black"
          ></path>
          <path
            d="M31.5576 3.46924H24.7554V30.7761H31.5576V3.46924Z"
            fill="#C00303"
          ></path>
          <path
            d="M15.7787 30.9999C18.2113 30.9999 20.1834 29.0207 20.1834 26.5793C20.1834 24.1379 18.2113 22.1587 15.7787 22.1587C13.3461 22.1587 11.374 24.1379 11.374 26.5793C11.374 29.0207 13.3461 30.9999 15.7787 30.9999Z"
            fill="#F9B231"
          ></path>
          <path
            d="M6.80217 3.46948H0V30.7764H6.80217V3.46948Z"
            fill="#0087C1"
          ></path>
          <path
            d="M0 3.46948L11.6529 19.9767H19.9047L8.25181 3.46948H0Z"
            fill="#009EE3"
          ></path>
          <path
            d="M31.5576 3.46924L19.9048 19.9765H11.653L23.3058 3.46924H31.5576Z"
            fill="#E3000B"
          ></path>
        </svg>
      </a>

      <img className={classes.calendarImage} alt="main banner" src={banner} />
      <EventFilters
        onFilterChange={filterEvents}
        events={eventsData}
        resultsAvailableCount={resultsAvailableCount}
        applicationAvailableCount={applicationAvailableCount}
      />
      <div className={classes.CardWrapper}>
        {filteredEvents.slice(first, first + rows).map((event, index) => (
          <Card
            key={`${event.id}-${index}`} // Adding index to ensure uniqueness
            id={event.id}
            mainImg={event.mainImg}
            time={event.start_date}
            location={event.event_region}
            title={event.event_name}
            description={event.additional_info}
            startDate={event.start_date}
            endDate={event.end_date}
            sportType={event.sport_type}
            eventType={event.event_type}
            eventStatus={event.event_status}
            eventOrg={event.event_org}
            eventAddress={event.event_address}
            eventPartDeadline={event.event_part_deadline}
            eventOrgHead={event.event_org_head}
            reqDocuments={event.req_documents}
            provisions={event.url}
            event_results={event.event_results}
            event_application={event.event_application}
          />
        ))}
      </div>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={filteredEvents.length}
        onPageChange={onPaginationChange}
        rowsPerPageOptions={[6, 12, 24]}
      />
      <p className={classes.footer}>
        © 2021 - {new Date().getFullYear()} АО «Мой спорт» <br />
        Все права защищены.
        <br /> При использовании материалов в любых целях, ссылка на
        первоисточник обязательна.
      </p>
    </div>
  );
}

export default CalendarPage;
