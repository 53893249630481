import React from "react";
import { Dialog } from "primereact/dialog";
import classes from "./MenuDialog.module.css";

const evenColor = "#fff";
const oddColor = "#f2f2f2";
const containerStyle = {
  fontSize: "1rem",
  lineHeight: "1.4",
};
const listItemStyle = {
  padding: "8px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
};
const labelStyle = {
  fontWeight: "bold",
};
const evenStyle = {
  ...listItemStyle,
  backgroundColor: evenColor,
};
const oddStyle = {
  ...listItemStyle,
  backgroundColor: oddColor,
};

export default function MenuPopup({ visible, onHide, eventInfo }) {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={"Вход в личные кабинеты"}
      style={{ width: "80%" }}
      breakpoints={{ "3840px": "960px", "960px": "75vw", "641px": "100vw" }}
    >
      <div className={classes.ButtonWrapper}>
        <a
          className={classes.Button}
          href="https://org.moisport.ru/schools"
          target="_blank"
          rel="noopener noreferrer"
        >
          Вход для спортивных организаций
        </a>
        <a
          className={classes.Button}
          href="https://moisport.ru/authorization_hello"
          target="_blank"
          rel="noopener noreferrer"
        >
          Вход для родителей
        </a>
        <a
          className={classes.Button}
          href="https://org.moisport.ru/federations"
          target="_blank"
          rel="noopener noreferrer"
        >
          Вход для Региональных спортивных Федераций
        </a>
      </div>
    </Dialog>
  );
}
