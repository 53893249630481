import React, { useState } from "react";
import classes from "./Card.module.css";
import timeIcon from "../../assets/icons/time.png";
import locationIcon from "../../assets/icons/location.png";
import EventPopup from "../Dialog/Dialog";
import img01 from "../../assets/card01.png";
import img02 from "../../assets/card02.png";
import img03 from "../../assets/card03.png";
import img04 from "../../assets/card04.png";
function Card({
  id,
  title,
  time,
  endDate,
  location,
  description,
  startDate,
  sportType,
  eventType,
  eventStatus,
  eventOrg,
  eventAddress,
  eventPartDeadline,
  eventOrgHead,
  reqDocuments,
  provisions,
  event_results,
  event_application,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const randomImages = [img01, img02, img03, img04];
  const randomMainImg =
    randomImages[Math.floor(Math.random() * randomImages.length)];

  // Функция для преобразования даты в формат "дд.мм.гг"
  const formatToDdMmYy = (dateString) => {
    const date = new Date(dateString);
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yy = date.getFullYear().toString().slice(-0);
    return `${dd}.${mm}.${yy}`;
  };

  const formattedTime = `${formatToDdMmYy(startDate)} — ${formatToDdMmYy(
    endDate
  )}`;

  return (
    <div className={classes.Card}>
      <div className={classes.CardImage}>
        <img src={randomMainImg} alt="event" onClick={togglePopup} />
      </div>
      <div className={classes.CardInnerWrapper}>
        <div classes={classes.HelperWrapper}>
          <div className={classes.CardTime}>
            <img src={timeIcon} alt="time icon" />
            {formattedTime}
          </div>
          <div className={classes.CardLocation}>
            <img src={locationIcon} alt="location icon" />
            {location}
          </div>
          <div className={classes.CardTitle} onClick={togglePopup}>
            {title}
          </div>
          <div className={classes.CardDescription}>{description}</div>
        </div>

        <div classes={classes.HelperWrapper2}>
          <div className={classes.CardButtonsWrapper}>
            <a
              href={
                event_application === "Да"
                  ? `https://org.moisport.ru/public-events-schedule/${id}`
                  : "#"
              }
              target={event_application === "Да" ? "_blank" : ""}
              rel="noreferrer"
            >
              <button
                className={
                  event_application === "Да"
                    ? classes.Button
                    : classes.ButtonTransparent
                }
                disabled={event_application !== "Да"}
              >
                Подать заявку
              </button>
            </a>

            <a
              href={
                event_results === "Да"
                  ? `https://org.moisport.ru/public-events-schedule/${id}/results`
                  : "#"
              }
              target={event_results === "Да" ? "_blank" : ""}
              rel="noreferrer"
            >
              <button
                className={
                  event_results === "Да"
                    ? classes.ButtonGrey
                    : classes.ButtonTransparent
                }
                disabled={event_results !== "Да"}
              >
                Результаты
              </button>
            </a>
            {provisions && (
              <a
                className={classes.ButtonLink}
                href={
                  provisions !== null
                    ? `https://org.moisport.ru/public-events-schedule/${id}/info`
                    : "#"
                }
                target="_blank"
                rel="noreferrer"
              >
                Положение
              </a>
            )}
          </div>
        </div>
      </div>
      <EventPopup
        visible={showPopup}
        onHide={togglePopup}
        eventInfo={{
          id,
          title,
          time,
          location,
          description,
          startDate,
          endDate,
          sportType,
          eventType,
          eventStatus,
          eventOrg,
          eventAddress,
          eventPartDeadline,
          eventOrgHead,
          reqDocuments,
        }}
      />
    </div>
  );
}

export default Card;
