import React, { useEffect, useState } from "react";
import classes from "./Filters.module.css";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import filter from "../../assets/calendar/filter.json";
import { useSearchParams } from "react-router-dom";

function EventFilters({
  initialLocation,
  onFilterChange,
  events,
  resultsAvailableCount,
  applicationAvailableCount,
}) {
  const filterData = filter;
  const sportsOptions = [...new Set(events.map((event) => event.sport_type))];
  const eventTypeOptions = [
    ...new Set(events.map((event) => event.event_type)),
  ];
  const statusOptions = [...new Set(events.map((event) => event.event_status))];
  const ageOptions = [...new Set(filterData.ageType.map((type) => type))];
  const locationOptions = [
    ...new Set(events.map((event) => event.event_region)),
  ];

  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAge, setSelectedAge] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [checkedApplication, setCheckedApplication] = useState(false); // Для поля event_application
  const [checkedResults, setCheckedResults] = useState(false); // Для поля event_results
  const [searchParams, setSearchParams] = useSearchParams();
  const locationParam = searchParams.get("location");

  // Функция обновления фильтров
  const handleFiltersChange = () => {
    const filters = {
      sport_type: selectedSport,
      event_type: selectedEventType,
      event_status: selectedStatus,
      age: selectedAge,
      event_region: selectedLocation,
      start_date: selectedDate,
      event_application: checkedApplication ? "Да" : null, // Фильтр по приёму заявок
      event_results: checkedResults ? "Да" : null, // Фильтр по результатам
    };
    console.log("Filters applied:", filters); // Лог
    onFilterChange(filters); // Обновление карточек
  };

  useEffect(() => {
    if (locationParam !== "undefined") {
      setSelectedLocation(locationParam);
    } else {
      setSelectedLocation(null);
    }
    handleFiltersChange(); // Обновление фильтров при изменении зависимостей
  }, [
    selectedSport,
    selectedEventType,
    selectedStatus,
    selectedAge,
    selectedLocation,
    selectedDate,
    checkedApplication, // Учитываем изменение фильтра по заявкам
    checkedResults, // Учитываем изменение фильтра по результатам
  ]);

  // Функция сброса всех фильтров
  const resetFilters = () => {
    delateSearchParam("location");
    setSelectedSport(null);
    setSelectedEventType(null);
    setSelectedStatus(null);
    setSelectedAge(null);
    setSelectedLocation(null);
    setSelectedDate(null);
    setCheckedApplication(false); // Сброс фильтра по приёму заявок
    setCheckedResults(false); // Сброс фильтра по результатам
    onFilterChange({}); // Сбрасываем все фильтры
  };

  const delateSearchParam = (param) => {
    searchParams.delete(param);
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.filters}>
      <Dropdown
        style={{ width: "180px" }}
        value={selectedSport}
        filter
        className="fs9"
        showClear
        options={[
          { label: "Все виды спорта", value: null },
          ...sportsOptions.map((sport) => ({ label: sport, value: sport })),
        ]}
        onChange={(e) => {
          setSelectedSport(e.value);
        }}
        placeholder="Вид спорта"
      />
      <Dropdown
        style={{ width: "180px" }}
        value={selectedEventType}
        filter
        className="fs9"
        showClear
        options={eventTypeOptions.map((eventType) => ({
          label: eventType,
          value: eventType,
        }))}
        onChange={(e) => {
          setSelectedEventType(e.value);
        }}
        placeholder="Вид мероприятия"
      />
      <Dropdown
        style={{ width: "180px" }}
        value={selectedStatus}
        filter
        showClear
        className="fs9"
        options={statusOptions.map((status) => ({
          label: status,
          value: status,
        }))}
        onChange={(e) => {
          setSelectedStatus(e.value);
        }}
        placeholder="Cтатус мероприятия"
      />
      <Dropdown
        style={{ width: "180px" }}
        value={selectedAge}
        filter
        showClear
        className="fs9"
        options={ageOptions.map((age) => ({
          label: age.categoryName,
          value: age.categoryType,
        }))}
        onChange={(e) => {
          setSelectedAge(e.value);
        }}
        placeholder="Возрастная группа"
      />
      <Dropdown
        style={{ width: "180px" }}
        value={selectedLocation}
        filter
        className="fs9"
        showClear
        options={locationOptions.map((location) => ({
          label: location,
          value: location,
        }))}
        onChange={(e) => {
          setSelectedLocation(e.value);
          e.value
            ? setSearchParams({ location: e.value })
            : delateSearchParam("location");
        }}
        placeholder="Место проведения"
      />
      <Calendar
        style={{ width: "180px" }}
        value={selectedDate}
        showClear
        className="fs9"
        onChange={(e) => {
          setSelectedDate(e.value);
        }}
        placeholder="Дата проведения"
      />
      <div className={classes.filterCheckbox}>
        <InputSwitch
          checked={checkedApplication}
          onChange={(e) => {
            setCheckedApplication(e.value);
          }}
        />
        <p>Открыт прием заявок ({applicationAvailableCount})</p>
      </div>
      <div className={classes.filterCheckbox}>
        <InputSwitch
          checked={checkedResults}
          onChange={(e) => {
            setCheckedResults(e.value);
          }}
        />
        <p>С результатами всего: ({resultsAvailableCount})</p>
      </div>
      <button className={classes.resetButton} onClick={resetFilters}>
        Сбросить фильтры
      </button>
    </div>
  );
}

export default EventFilters;
